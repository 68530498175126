import { Edge } from "./Edge_class";
import { Vertex } from "./Vertex_class";

export class Graph {

    global_id_vertex:number;
    global_id_edge:number;

    graph_vertices:Vertex[];
    graph_edges:Edge[];

    constructor () 
    {
        this.global_id_vertex = 0;
        this.global_id_edge = 0;

        this.graph_vertices = [];
        this.graph_edges = [];
    }
};