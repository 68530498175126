import React, { } from 'react';

import "./FourthBanner.css";

import Compass from '../Compass/Compass';

const FourthBanner:React.FunctionComponent = () => 
{
    return (        
        <div className="FourthBanner">
            <div className="CompassContainer">
                <Compass angle_x={0} angle_y={0}/>
            </div>
            <div className="FourthBannerContainer">
                <div>
                    <p className="FourthBannerBoldText">LEDR Orchestra can automate your analytical function​</p>
                </div>
                <p className="FourthBannerText">If our default national security grade privacy capabilities are not 
                                                sufficient, LEDR Orchestra can even automate your analytics function 
                                                so nobody needs to be involved once operational.​</p>
            </div>
        </div>
    );
};

export default FourthBanner;