import { Vertex } from "./Vertex_class";

export class Edge {
    
    id:number;

    from:Vertex;
    to:Vertex;
    current:Vertex;
    current_animation_step:number;
    edge_color:string;
    edge_complete:boolean;

    constructor (edge_id:number, edge_from:Vertex, edge_to:Vertex)
    {
        this.id = edge_id;
        
        this.from = edge_from;
        this.to = edge_to;
        this.current = new Vertex(-1);
        this.current_animation_step = 0;
        this.edge_color = "";
        this.edge_complete = false;
    }
};