import { Graph } from "../Classes/Graph_class";
import { Subgraph } from "../Classes/Subgraph_class";
import { Mouse_Move_Event } from "./mouse_move_handler";
import { animate } from "./animation_manager";
import { Build_Graph, Build_Subgraph, Init_Graph_Vertex_Data } from "./entities_building";
//import { Vector_Are_Equals } from "./Utils/vector_utils";

//*********************************************************************************************
//
//  Nomenclature: 
//	  Const Variables: SNACK_CASE with all the letters Uppercase
//	  Mutable Variables: snake_case
//	  Non Primitive Types(Interfaces) / Enum: PascalCase (first letter in Uppercase)
//		  - In Enums ALL enum values in Uppercase (all the word letters)
//	  Functions: Snake_Case with Uppercase to every word's first letter (Pascal_Snake_Case)
//
//*********************************************************************************************
const PERCENT_VERTEX:number = 17;
const PERCENT_EDGES:number = 10;

let graph:Graph;
let subgraph:Subgraph;

export const Canvas_Manager = (canvas_context:CanvasRenderingContext2D) => 
{
	graph = Build_Graph(graph);
	
	if (graph)
		subgraph = Build_Subgraph(graph, subgraph, PERCENT_VERTEX, PERCENT_EDGES);

	for (const vertex of graph.graph_vertices)
		if (vertex.in_subgraph === false)
			Init_Graph_Vertex_Data(vertex);

	animate(canvas_context, graph, subgraph);
};

window.addEventListener('mousemove', (e:MouseEvent) => Mouse_Move_Event(e, graph, subgraph));
