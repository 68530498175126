import React, { useState } from 'react';

import "./NavBar.css";

import logo from "../../Image/Logo/LogoWhite.png";

const NavBar:React.FunctionComponent = () => 
{
    const [navBarIsVisible, setNavBarIsVisible] = useState<boolean>(false);
    const [navBarLogoIsVisible, setnavBarLogoIsVisible] = useState<boolean>(false);

    const ScrollHandler = () => 
    {
      if (window.scrollY <= window.innerHeight)
        setnavBarLogoIsVisible(false);
      else 
        setnavBarLogoIsVisible(true);

      if (window.scrollY >= window.innerHeight)
        setNavBarIsVisible(true);
      else 
        setNavBarIsVisible(false);
    };

    window.addEventListener('scroll', ScrollHandler);

    return (
      <div className={navBarIsVisible ? "NavBar IsVisible" : "NavBar"}>
          <img className={navBarLogoIsVisible ? "Corp_Logo_Nav_Bar IsVisible" : "Corp_Logo_Nav_Bar"} 
               src={logo} 
               alt="Logo"></img>
      </div>  
    );
};

export default NavBar;