import React from 'react';

import "./ThirdBanner.css";

const ThirdBanner:React.FunctionComponent = () => 
{
    return (
        <div>
            <div className="TopThirdBanner">
                <div className="TopThirdBannerContainer">
                    <p className="TopThirdBannerBoldText">LEDR Orchestra powers interoperability​</p>
                    <p className="TopThirdBannerText">LEDR Orchestra is designed for current and future systems to work 
                                                         together - say goodbye to migrations just because you want to 
                                                         harness the value of data in older systems.​</p>
                </div>
            </div>

            <div className="BottomThirdBanner">
                <div className="BottomThirdBannerContainer">
                    <p className="BottomThirdBannerBoldText">LEDR Orchestra enables collaboration​</p>
                    <p className="BottomThirdBannerText">LEDR Orchestra privately and securely connects the dots between 
                                                      all relevant sources of knowledge based on policies set by its 
                                                      owners.​</p>
                </div>
            </div>
      </div>
    );
}

export default ThirdBanner; 