import { Random_Integer } from "../Functions/Utils/utils";
import { Vector2D } from "../Types/graphAnimation_types";
import { Edge } from "./Edge_class";
import { Vertex } from "./Vertex_class";

export class Subgraph {

    center_of_distribution:Vector2D;
    subgraph_vertices:Vertex[];
    subgraph_edges:Edge[];
    center_of_collapse:Vertex;
    center_of_collapse_already_selected:boolean;
    start_collapse:boolean;

    constructor ()
    {
        this.center_of_distribution = { x: 0, y: 0 };
        this.subgraph_vertices = [];
        this.subgraph_edges = [];
        this.center_of_collapse = new Vertex(Random_Integer(-30000, -1));
        this.center_of_collapse_already_selected = false;
        this.start_collapse = false;
    }
};