import { Vector2D } from "../Types/graphAnimation_types";

export class Vertex {

    id:number;
    coord:Vector2D;
    layer:number;
    color:string;
    size:number;
    in_subgraph:boolean;
    text_to_display:string;
    collapse_velocity:Vector2D;
    is_an_hypergraph:boolean;
    hypergraph_ring_size:number;
    current_hypergraph_ring_size:number;

    constructor (vertex_id:number)
    {
        this.id = vertex_id;
        this.coord = { x: 0,
                       y: 0 };
        this.layer = 0;
        this.color = "";
        this.size = 0;
        this.in_subgraph = false;
        this.text_to_display = "";
        this.collapse_velocity = { x: 0,
                                   y: 0 };
        this.is_an_hypergraph = false;
        this.hypergraph_ring_size = 0;
        this.current_hypergraph_ring_size = 0;
    } 
};