import React, { } from 'react';

import "./SecondBanner.css";

const SecondBanner:React.FunctionComponent = () => 
{
    return (
        <div className="SecondBanner">
            <div className="SecondBannerTextContainer">
                <p className="SecondBannerBoldText">LEDR Orchestra creates real-time digital representations or twins 
                                                    without needing to move, replicate or centralize data</p>
                <p className="SecondBannerText">Most companies use solutions that require you to bring data stored 
                                                in central  locations like data lakes to a central place to analyze.​ 
                                                What if you didn’t need to move data around? Instead, your analytics 
                                                can find all the relevant sources.​</p>
            </div>
            <div className="SecondBannerImage"></div>
        </div>
    );
};

export default SecondBanner;