import { Edge } from "../Classes/Edge_class";
import { Graph } from "../Classes/Graph_class";
import { Subgraph } from "../Classes/Subgraph_class";
import { Vertex } from "../Classes/Vertex_class";
import { Vector2D } from "../Types/graphAnimation_types";
import { Color_Selector, Cpy_Vertex, Random_Integer, Size_Selector } from "./Utils/utils";
import { Vector_Are_Equals } from "./Utils/vector_utils";

const AMOUNT_OF_VERTEX:number = 180;
const AMOUNT_OF_LAYER:number = 3;
const SPREADING_VERTEX_CONST:number = 4;
const FRAME_WIDTH_LIMIT:number = 200;
const FRAME_HEIGHT_LIMIT:number = 100;

//BUILDING GRAPH
export const Build_Graph = (old_graph:Graph):Graph =>
{
    let graph:Graph;

    if (!old_graph)
        graph = new Graph();
    else 
        graph = old_graph;

    Clear_Graph_From_Old_Subgraph_Vertices(graph);
	Add_Graph_Vertices(AMOUNT_OF_VERTEX - graph.graph_vertices.length, graph);
	Clear_Edges_Arr(graph.graph_edges);
	Init_Graph_Edges_Arr(graph);

	return (graph);
};

    const Clear_Graph_From_Old_Subgraph_Vertices = (graph:Graph) => 
    {
        if (graph)
        {
            for (let i = 0; i < graph.graph_vertices.length; ++i)
            {
                if (graph.graph_vertices[i].in_subgraph)
                {
                    graph.graph_vertices.splice(i, 1);
                    --i;
                }
            }
        }
    }

    const Add_Graph_Vertices = (num_of_vertex:number, graph:Graph) => 
    {
        for (let i = 0; i < num_of_vertex; ++i)
            graph.graph_vertices.push( new Vertex(New_Vertex(graph)) );
    };

        const New_Vertex = (graph:Graph):number => 
        { 
            return (graph.global_id_vertex++); 
        };

    const Clear_Edges_Arr = (edge_arr:Edge[]) => 
    { 
        edge_arr = [] 
    };

    const Init_Graph_Edges_Arr = (graph:Graph) => 
    {
        for (const vertex_from of graph.graph_vertices)
            for (const vertex_to of graph.graph_vertices)
                if (vertex_from !== vertex_to)
                    graph.graph_edges.push( new Edge(New_Edge(graph), vertex_from, vertex_to));
    };

        const New_Edge = (graph:Graph):number => 
        { 
            return (graph.global_id_edge++); 
        };



export const Build_Subgraph = (graph:Graph, old_subgraph:Subgraph, percent_vertices:number, percent_edges:number):Subgraph =>
{
    let subgraph:Subgraph;

    const screen_width:number = window.innerWidth;
    const screen_height:number = window.innerHeight;
    const spreading_variance = graph.graph_vertices.length * SPREADING_VERTEX_CONST;

    if (!old_subgraph)
        subgraph = new Subgraph();
    else 
    {
        Clear_Subgraph(old_subgraph);
        subgraph = old_subgraph;
    }

    subgraph.center_of_distribution = { x: Random_Integer((screen_width * 0.20), (screen_width) * 0.80), 
                                        y: Random_Integer((screen_height * 0.45), (screen_height) * 0.55) };

	Link_Graph_Vertices_To_Subgraph(graph, subgraph, percent_vertices, spreading_variance);

    for (const vertex of subgraph.subgraph_vertices)
        if (Vector_Are_Equals(vertex.coord, {x: 0, y: 0}))
		    Init_Subgraph_Vertex_Data(vertex, spreading_variance, subgraph.center_of_distribution);

	Link_Graph_Edges_To_Subgraph(graph, subgraph, percent_edges);
	
	for (const edge of subgraph.subgraph_edges)
	{
		Cpy_Vertex(edge.from, edge.current);
		Select_Color_Edge(edge);
	}

	return (subgraph);
}

    const Clear_Subgraph = (subgraph:Subgraph) => 
    {
        subgraph.subgraph_vertices = [];
        subgraph.subgraph_edges = [];
        subgraph.center_of_collapse_already_selected = false;
        subgraph.start_collapse = false;
    };

    const Link_Graph_Vertices_To_Subgraph = (graph:Graph, subgraph:Subgraph, percent_vertices:number, spreading_variance:number) => 
    {

        for (const vertex_ref of graph.graph_vertices)
        {
            if (Math.random() < (percent_vertices * 0.01))
            {
                if (Vector_Are_Equals(vertex_ref.coord, {x: 0, y: 0}) || 
                    In_Center_Of_Distribution_Area(vertex_ref, subgraph.center_of_distribution,  spreading_variance))
                {
                    subgraph.subgraph_vertices.push(vertex_ref);
                    vertex_ref.in_subgraph = true;
                }
            }
        }
    };

        const In_Center_Of_Distribution_Area = (vertex:Vertex, center_of_distribution:Vector2D, spreading_variance:number) =>
        {
            return ( (vertex.coord.x >= center_of_distribution.x - spreading_variance) &&
                     (vertex.coord.x <= center_of_distribution.x + spreading_variance) && 
                     (vertex.coord.y >= center_of_distribution.y - spreading_variance) &&
                     (vertex.coord.y <= center_of_distribution.y + spreading_variance) );
        };

    const Init_Subgraph_Vertex_Data = (vertex:Vertex, spreading_variance:number, center_of_distribution:Vector2D) => 
    {

        vertex.coord.x = Random_Integer(center_of_distribution.x - spreading_variance, center_of_distribution.x + spreading_variance);
        vertex.coord.y = Random_Integer(center_of_distribution.y - spreading_variance, center_of_distribution.y + spreading_variance);
        vertex.layer = Random_Integer(1, AMOUNT_OF_LAYER);
        vertex.size = Size_Selector(vertex.layer);
        vertex.color = Color_Selector(vertex.layer);
    }

    const Link_Graph_Edges_To_Subgraph = (graph:Graph, subgraph:Subgraph, percent_edges:number) => 
    {
        for (const edge of graph.graph_edges)
        {
            const link_to_subgraph:boolean = Math.random() < (percent_edges * 0.01);

            if (Does_Edge_Contains_Vertex(subgraph.subgraph_vertices, edge) && link_to_subgraph)
                subgraph.subgraph_edges.push(edge);
        }
    };

        const Does_Edge_Contains_Vertex = (subgraph_vertices:Vertex[], graph_edge:Edge):boolean => 
        {	
            return ( subgraph_vertices.indexOf(graph_edge.from) >= 0 && 
                     subgraph_vertices.indexOf(graph_edge.to  ) >= 0 );
        };

    const Select_Color_Edge = (edge:Edge) => 
    {
        if (edge.from.layer === 1 && edge.to.layer === 1)	
            edge.edge_color = "rgba(88, 98, 141, 1)";
        else if (edge.from.layer === 1 || edge.to.layer === 1)	
            edge.edge_color = "rgba(88, 98, 141, 0.8)";
        else if (edge.from.layer === 2 && edge.to.layer === 2)	
            edge.edge_color = "rgba(88, 98, 141, 0.5)";
        else if (edge.from.layer === 2 || edge.to.layer === 2)	
            edge.edge_color = "rgba(88, 98, 141, 0.4)";
        else if (edge.from.layer === 3 && edge.to.layer === 3)	
            edge.edge_color = "rgba(88, 98, 141, 0.2)";
    };



export const Init_Graph_Vertex_Data = (vertex:Vertex) => 
{
	const screen_width:number = window.innerWidth;
	const screen_height:number = window.innerHeight;

	vertex.coord.x = Random_Integer(-FRAME_WIDTH_LIMIT, screen_width + FRAME_WIDTH_LIMIT);
	vertex.coord.y = Random_Integer(-FRAME_HEIGHT_LIMIT, screen_height + FRAME_HEIGHT_LIMIT);
	vertex.layer = Random_Integer(1, AMOUNT_OF_LAYER);
	vertex.size = Size_Selector(vertex.layer);
    vertex.color = Color_Selector(vertex.layer);
}